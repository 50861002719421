// 商务合作
<template>
  <div class="cooperation">
    <el-image class="cooperation-img" :src="require('@/assets/images/swhz-bn1.png')"></el-image>
    <div class="content">
      <section class="cooperation-profile ta-left">
        中金浩资产评估有限责任公司目前已拥有资产评估执业资质、会计师事务所执业资质、房地产评估执业资质、律师事务所执业资质。为了更好、更快捷服务于全国各地中小企业资产评估及相关业务需求，公司现诚邀全国各地区域性商务合作伙伴共同致力于市场开拓。
      </section>
      <section class="cooperation-require align-center">
        <el-image class="cooperation-img" :src="require('@/assets/images/swhz-img2.png')"></el-image>
        <div class="require-info">
          <h2 class="info-title ta-left">合作伙伴要求</h2>
          <el-row class="info-list">
            <el-col class="info-item ta-left" :span="24" v-for="(item,index) in requires" :key="index">
              {{item}}
            </el-col>
          </el-row>
        </div>
      </section>
      <section class="cooperation-phone justify-center">
        <div class="phone-title">
          <h2 class="info-title ">咨询电话</h2>
          <p>{{'Consultation telephone'.toLocaleUpperCase()}}</p>
        </div>
        <el-row class="phone-list justify-center flex-warp" v-if="getConfigs.business_cooperation">
          <el-col class="phone-item flex-center" :span="8"
            v-for="(item,index) in getConfigs.business_cooperation.split(',')" :key="index">
            <el-image :src="require('@/assets/images/swhz-img1.png')"></el-image>
            {{item}}
          </el-col>
        </el-row>
      </section>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex"
export default {
  name: 'Cooperation',
  computed: {
    ...mapGetters(['getConfigs'])
  },
  data () {
    return {
      requires: ['具有一定地方资源关系，能积极开拓当地评估相关市场；', '做事诚实、认真、守信 ；', '愿意接受总公司的合作管理制度，认同总公司品牌发展理念；']
    };
  },
};
</script>

<style scoped lang="less">
.cooperation {
  text-align: center;
  .content {
    position: relative;
  }
}
section {
  border-radius: 10px;
  margin-bottom: 30px;
  background-color: #fff;
}
.cooperation-profile {
  padding: 45px 65px;
  margin-top: -65px;
  font-size: 18px;
  letter-spacing: 1px;
  color: #3f3f3f;
  line-height: 30px;
}
.cooperation-require {
  height: 325px;
  .el-image {
    width: 473px;
    height: 1005;
  }
  .require-info {
    flex: 1;
    margin-left: 95px;
    .info-title {
      margin-bottom: 47px;
    }
    .info-item {
      font-size: 18px;
      color: #333333;
      font-weight: bold;
      margin: 15px 0;
      letter-spacing: 1px;
      position: relative;
      margin-left: 16px;
      &::before {
        content: "";
        position: absolute;
        top: 27%;
        left: -16px;
        width: 8px;
        height: 8px;
        background-color: #fbd690;
        border-radius: 50%;
      }
    }
  }
}
.cooperation-phone {
  height: 325px;
  flex-direction: column;
  .phone-title {
    margin-bottom: 50px;
    .info-title {
      margin-bottom: 13px;
    }
    p {
      font-size: 18px;
      color: #eaeaea;
      font-weight: bold;
    }
  }
  .phone-item {
    color: #444444;
    font-size: 30px;
    font-weight: bold;
    letter-spacing: 2px;
    margin: 20px 0;
    .el-image {
      vertical-align: middle;
      margin-right: 8px;
    }
  }
}
.info-title {
  font-size: 33px;
  color: #909090;
}
.ta-left {
  text-align: left;
}
</style>
